<template>
  <div>
    <div class="all-height d-flex justify-center">
      <div class="all-width">
        <v-card :loading="loading" :disabled="loading" loader-height="2">
          <v-card-text class="pb-0">
            <div class="d-flex align-center">
              <v-btn
                rounded
                color="primary"
                v-if="$nova.hasRight(right, 'add')"
                @click="addfile"
                elevation="0"
                small
                ><v-icon>mdi-plus</v-icon>Add</v-btn
              >
              <v-btn icon small class="ml-1" @click="refreshData()">
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
              <lb-string
                class="ml-1"
                label="Search"
                outlined
                hidedetails
                v-model="seachList"
              ></lb-string>
              <v-spacer></v-spacer>
              <v-chip label small class="border-left-error" outlined
                >Disabled</v-chip
              >
            </div>
          </v-card-text>

          <v-card-text>
            <!-- <v-simple-table class="pbc-view-table" dense>
              <thead>
                <tr>
                  <th scope="#" class="px-4 text-center">#</th>
                  <th scope="Name">Name</th>
                  <th scope="columns">Columns</th>
                  <th scope="columns">Alias</th>

                  <th scope="Action">Action</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="(v, k) in searchResult">
                  <tr :key="k">
                    <td
                      :class="`${
                        v.status
                          ? 'border-left-transparent'
                          : 'border-left-error'
                      }`"
                    >
                      <span>{{ k + 1 }}</span>
                    </td>
                    <td>
                      <span
                        v-html="$nova.getHighlitedText(v.name || '', seachList)"
                      ></span>
                    </td>
                    <td>
                      <span v-for="(x, y) in v.columns" :key="y">
                        <v-tooltip bottom content-class="tooltip-bottom">
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip
                              x-small
                              class="ma-1 pl-0"
                              outlined
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-chip
                                rounded
                                class="px-2 mr-2"
                                :color="colors[0]"
                                v-if="x.type == 'string'"
                              >
                                S
                              </v-chip>
                              <v-avatar
                                rounded
                                class="px-2 mr-2"
                                :color="colors[1]"
                                v-else-if="x.type == 'number'"
                              >
                                N
                              </v-avatar>
                              <v-avatar
                                rounded
                                class="px-2 mr-2"
                                :color="colors[2]"
                                v-else-if="x.type == 'date'"
                              >
                                D
                              </v-avatar>
                              <v-avatar
                                rounded
                                class="px-2 mr-2"
                                :color="colors[3]"
                                v-else-if="x.type == 'time'"
                              >
                                T
                              </v-avatar>
                              <v-avatar
                                rounded
                                class="px-2 mr-2"
                                :color="colors[4]"
                                v-else-if="x.name || ''"
                              >
                              </v-avatar>
                              {{ x.name || "" }}
                            </v-chip>
                          </template>
                          <div class="text-center">
                            {{ x.type }}
                          </div>
                        </v-tooltip>
                      </span>
                    </td>
                    <td>{{ v.alias }}</td>
                    <td> -->
            <!-- <v-menu offset-y transition="slide-y-transition">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="view-on-hover-item-d mx-0 px-0"
                            icon
                            v-bind="attrs"
                            v-on="on"
                            ><v-icon>mdi-menu</v-icon></v-btn
                          >
                        </template>
                        <v-list dense class="pa-0 border-left-default">
                          <v-list-item @click="fileEdit(v)">
                            <v-list-item-title
                              ><v-icon left>mdi-pencil</v-icon>Edit
                            </v-list-item-title>
                          </v-list-item>
                          <v-list-item
                            v-if="v.status"
                            @click="fileInCompeted(v._id)"
                          >
                            <v-list-item-title
                              ><v-icon color="error" left>mdi-cancel</v-icon
                              >Disable</v-list-item-title
                            >
                          </v-list-item>
                          <v-list-item v-else @click="fileCompeted(v._id)">
                            <v-list-item-title
                              ><v-icon color="success" left
                                >mdi-check-bold </v-icon
                              >Enable</v-list-item-title
                            >
                          </v-list-item>
                        </v-list>
                      </v-menu> -->

            <!-- <div class="d-flex justify-center">
                        <v-tooltip
                          bottom
                          content-class="tooltip-bottom"
                          v-if="$nova.hasRight(right, 'edit')"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              small
                              v-bind="attrs"
                              v-on="on"
                              @click="fileEdit(v)"
                            >
                              <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                          </template>
                          <span>Edit File</span>
                        </v-tooltip>
                        <v-tooltip
                          bottom
                          content-class="tooltip-bottom"
                          v-if="
                            v.status && $nova.hasRight(right, 'enable/disable')
                          "
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              small
                              color="error"
                              v-bind="attrs"
                              v-on="on"
                              @click="fileInCompeted(v._id)"
                            >
                              <v-icon>mdi-close-circle</v-icon>
                            </v-btn>
                          </template>
                          <span>Disable</span>
                        </v-tooltip>
                        <v-tooltip
                          bottom
                          content-class="tooltip-bottom"
                          v-else-if="$nova.hasRight(right, 'enable/disable')"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              small
                              color="success"
                              v-bind="attrs"
                              v-on="on"
                              @click="fileCompeted(v._id)"
                            >
                              <v-icon>mdi-check-circle</v-icon>
                            </v-btn>
                          </template>
                          <span>Enable</span>
                        </v-tooltip>
                      </div>
                    </td>
                  </tr>
                </template>
              </tbody>
            </v-simple-table> -->

            <DataTable
              :headers="headers"
              :items="formatedData"
              :enableslot="['action', 'columns']"
              :loading="loading"
            >
              <template v-slot:columns="{ item }">
                <span v-for="(x, y) in item.columns" :key="y">
                  <v-tooltip bottom content-class="tooltip-bottom">
                    <template v-slot:activator="{ on, attrs }">
                      <v-chip
                        x-small
                        class="ma-1 pl-0"
                        outlined
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-chip
                          rounded
                          class="px-2 mr-2"
                          :color="colors[0]"
                          v-if="x.type == 'string'"
                        >
                          S
                        </v-chip>
                        <v-avatar
                          rounded
                          class="px-2 mr-2"
                          :color="colors[1]"
                          v-else-if="x.type == 'number'"
                        >
                          N
                        </v-avatar>
                        <v-avatar
                          rounded
                          class="px-2 mr-2"
                          :color="colors[2]"
                          v-else-if="x.type == 'date'"
                        >
                          D
                        </v-avatar>
                        <v-avatar
                          rounded
                          class="px-2 mr-2"
                          :color="colors[3]"
                          v-else-if="x.type == 'time'"
                        >
                          T
                        </v-avatar>
                        <v-avatar
                          rounded
                          class="px-2 mr-2"
                          :color="colors[4]"
                          v-else-if="x.name || ''"
                        >
                        </v-avatar>
                        {{ x.name || "" }}
                      </v-chip>
                    </template>
                    <div class="text-center">
                      {{ x.type }}
                    </div>
                  </v-tooltip>
                </span>
              </template>
              <template v-slot:action="{ item }">
                <div class="d-flex justify-center">
                  <v-tooltip
                    bottom
                    content-class="tooltip-bottom"
                    v-if="$nova.hasRight(right, 'edit')"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        small
                        v-bind="attrs"
                        v-on="on"
                        @click="fileEdit(item)"
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Edit File</span>
                  </v-tooltip>
                  <v-tooltip
                    bottom
                    content-class="tooltip-bottom"
                    v-if="
                      item.status && $nova.hasRight(right, 'enable/disable')
                    "
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        small
                        color="error"
                        v-bind="attrs"
                        v-on="on"
                        @click="fileInCompeted(item._id)"
                      >
                        <v-icon>mdi-close-circle</v-icon>
                      </v-btn>
                    </template>
                    <span>Disable</span>
                  </v-tooltip>
                  <v-tooltip
                    bottom
                    content-class="tooltip-bottom"
                    v-else-if="$nova.hasRight(right, 'enable/disable')"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        small
                        color="success"
                        v-bind="attrs"
                        v-on="on"
                        @click="fileCompeted(item._id)"
                      >
                        <v-icon>mdi-check-circle</v-icon>
                      </v-btn>
                    </template>
                    <span>Enable</span>
                  </v-tooltip>
                </div>
              </template>
            </DataTable>
          </v-card-text>
        </v-card>
      </div>
    </div>
    <v-dialog v-model="filedialog" max-width="600" persistent>
      <v-card class="">
        <v-card-text class="primary white--text d-flex align-center py-2 px-4">
          <span class="subtitle-1 white--text" v-if="fileObj._id"
            >Update File
          </span>
          <span class="subtitle-1 white--text" v-else>Create New File</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="filedialog = false" class="ml-2 white--text"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-text>
        <v-card-text class="mt-4 pt-4">
          <v-row>
            <v-col cols="12" class="my-0 py-1">
              <lb-string
                label="Name"
                v-model="fileObj.name"
                :error="errors['name']"
              />
            </v-col>
            <v-col cols="12" class="my-0 py-1">
              <lb-string label="Name Alias" v-model="fileObj.alias" />
            </v-col>

            <v-col cols="12" class="my-0 py-1">
              <div class="d-flex align center">
                <v-btn
                  small
                  color="columns"
                  outlined
                  text
                  class="ml-2 shadow-off"
                  @click="columnsDialog = true"
                  >Add Column</v-btn
                >
              </div>
              <span v-if="errors.columns" style="color: #b22222">
                Column required
              </span>
              <span v-if="errors.columnSame" style="color: #b22222">
                columns should be different
              </span>
            </v-col>

            <v-col cols="12" class="my-0 py-1" v-if="columnsItems.length > 0">
              <v-simple-table class="pbc-view-table" dense>
                <thead>
                  <tr>
                    <th scope="#" class="">#</th>
                    <th scope="Name">Name</th>
                    <th scope="columns">Type</th>
                    <th scope="Action">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(v, k) in columnsItems">
                    <tr :key="k">
                      <td>
                        <span>{{ k + 1 }}</span>
                      </td>
                      <td>{{ v.name }}</td>
                      <td>{{ v.type }}</td>

                      <td>
                        <v-icon class="ma-1" @click="removeColum(v)"
                          >mdi-delete</v-icon
                        >
                      </td>
                    </tr>
                  </template>
                </tbody>
              </v-simple-table>
            </v-col>

            <v-col cols="6" class="my-0 py-1">
              <v-switch
                v-model="fileObj.status"
                inset
                color="success"
                label="status"
              ></v-switch>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-text class="d-flex">
          <v-spacer></v-spacer>
          <v-btn small color="primary" v-if="fileObj._id" @click="fileUpdate()"
            >Update</v-btn
          >
          <v-btn small color="primary" v-else @click="fileCreate()"
            >Create</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="columnsDialog" max-width="600" persistent>
      <v-card class="">
        <v-card-text class="primary white--text d-flex align-center py-2 px-4">
          <span class="subtitle-1 white--text">Create Columns</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="columnsDialog = false" class="ml-2 white--text"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-text>
        <v-card-text class="mt-4 pt-4">
          <v-row>
            <v-col cols="12" class="my-0 py-1">
              <lb-string label="Column Name" v-model="columnObj.name" />
            </v-col>
            <v-col cols="12" class="my-0 py-1">
              <lb-dropdown
                class="flex-grow-1"
                label="Columns Type"
                v-model="columnObj.type"
                :items="typeItem"
                itemtext="name"
                itemvalue="value"
              />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-text class="d-flex">
          <v-spacer></v-spacer>
          <v-btn small color="primary" @click="columnCreate()">Create</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import DataTable from "../../../../views/common/DataTable.vue";
export default {
  data() {
    return {
      right: "conserve_file",
      seachList: null,
      filedialog: false,
      columnsDialog: false,
      loading: false,
      fileList: null,
      columnsItems: [],
      colors: this.$nova.getColors(4, 0.2),
      typeItem: [
        { _id: 1, value: "string", name: "String" },
        { _id: 2, value: "number", name: "Number" },
        { _id: 3, value: "date", name: "Date" },
        { _id: 4, value: "time", name: "Time" },
      ],
      editId: null,
      fileObj: {},
      columnObj: {},
      errors: {},
      formatedData: [],
      headers: [
        {
          value: "indexofno",
          text: "#",
          sortable: false,
          filterable: false,
          lock: true,
          visible: false,
        },
        {
          value: "name",
          text: "name",
          sortable: true,
          filterable: true,
          lock: true,
          visible: false,
        },
        {
          value: "columns",
          text: "columns",
          sortable: true,
          lock: true,
          visible: false,
        },
        {
          value: "created_at",
          text: "Created At",
          sortable: true,
          filterable: true,
          datatype: "date",
          lock: false,
          visible: false,
        },
        {
          value: "updated_at",
          text: "Updated At",
          sortable: true,
          filterable: true,
          datatype: "date",
          lock: false,
          visible: false,
        },
        {
          value: "action",
          text: "Action",
          sortable: false,
          datatype: "action",
          lock: true,
          visible: false,
          alignment: "text-center",
        },
      ],
    };
  },
  components: {
    DataTable,
  },
  created() {
    this.refreshData();
  },
  activated() {
    this.refreshData();
  },
  computed: {
    searchResult() {
      let tempList = this.fileList;
      if (this.seachList != "" && this.seachList) {
        tempList = tempList.filter((item) => {
          return item.name.toUpperCase().includes(this.seachList.toUpperCase());
        });
      }

      return tempList;
    },
  },
  methods: {
    formatdData() {
      let ObjectTemp = {};
      let ArrayTemp = [];
      this.fileList.forEach((item, index) => {
        ObjectTemp = {
          __key: index,
          __formatedkey: index,
          _id: item._id,
          indexofno: index + 1,
          name: item.name,
          columns: item.columns,
          alias: item.alias,
          created_at: item.created_at,
          updated_at: item.updated_at,
          status: item.status,
        };
        ArrayTemp.push(ObjectTemp);
      });
      this.formatedData = ArrayTemp;
    },
    refreshData() {
      this.loading = true;
      this.axios
        .post("/v2/conserve/file/get")
        .then((dt) => {
          if (dt.data.status === "success") {
            this.fileList = dt.data.data;
            this.formatdData();
          } else throw new Error(dt.data.message);
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    addfile() {
      this.fileObj = {};
      this.errors = {};
      this.columnsItems = [];
      this.filedialog = true;
    },
    fileCreate() {
      this.loading = true;
      this.fileObj.columns = this.columnsItems;
      this.axios
        .post("/v2/conserve/file/add", { data: this.fileObj })
        .then((dt) => {
          if (dt.data.status === "success") {
            this.fileObj = {};
            this.filedialog = false;
            this.$store.commit("sbSuccess", "added successfully");
            this.refreshData();
          } else {
            this.errors = {};
            if (dt.data.data.length > 0) {
              if (dt.data.data[0].index0) this.errors = dt.data.data[0].index0;
            }
            if (dt.data.message == "columns should be different") {
              this.errors.columnSame = "columns should be different";
            } else throw new Error(dt.data.message);
          }
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fileEdit(item) {
      this.fileObj = { ...item };
      this.columnsItems = [...item.columns];
      this.filedialog = true;
      this.errors = {};
    },
    fileUpdate() {
      this.loading = true;
      this.fileObj.columns = this.columnsItems;
      this.axios
        .post("/v2/conserve/file/edit/" + this.fileObj._id, {
          data: this.fileObj,
        })
        .then((dt) => {
          if (dt.data.status === "success") {
            this.filedialog = false;
            this.$store.commit("sbSuccess", "updated successfully");
            this.refreshData();
          } else {
            this.errors = {};
            if (dt.data.data.length > 0) {
              if (dt.data.data[0].index0) this.errors = dt.data.data[0].index0;
            }
            throw new Error(dt.data.message || "Error File Update");
          }
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fileInCompeted(id) {
      this.loading = true;
      this.axios
        .post("/v2/conserve/file/disable/" + id)
        .then((dt) => {
          if (dt.data.status === "success") {
            this.$store.commit("sbSuccess", "disable successfully");
            this.refreshData();
          } else throw new Error(dt.data.message || "Error Disable File");
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fileCompeted(id) {
      this.loading = true;
      this.axios
        .post("/v2/conserve/file/enable/" + id)
        .then((dt) => {
          if (dt.data.status === "success") {
            this.$store.commit("sbSuccess", "enable successfully");
            this.refreshData();
          } else throw new Error(dt.data.message || "Error Enable File");
        })
        .catch((err) => {
          this.$store.commit("sbError", err.message || err || "Unknown error!");
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    columnCreate() {
      let columalt = this.columnsItems.filter(
        (x) => x.name == this.columnObj.name
      );
      if (columalt.length) {
        this.errors.columnSame = "columns should be different";
        this.columnsDialog = false;
      } else {
        this.errors.columnSame = "";
        this.columnsItems.push(this.columnObj);
        this.columnObj = {};
        this.columnsDialog = false;
      }
    },
    removeColum(item) {
      this.editedIndex = this.columnsItems.indexOf(item);
      this.columnsItems.splice(this.editedIndex, 1);
      this.columnsDialog = false;
    },
  },
};
</script>